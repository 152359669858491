import React from 'react'
import tw, { styled } from 'twin.macro'
import Img from 'gatsby-image'
import Quote from '../../images/Quote.svg'
const Testimonial1Wrapper = styled.div`
  .testimonial-photo {
    background: #bddcea;
  }
`
const Testimonial1 = ({ testimonial }) => {

  return (
    <Testimonial1Wrapper
      tw="container py-8 md:py-14"
      className="testimonials">
      <div tw="grid grid-cols-1 md:grid-cols-3 bg-air rounded-lg">
        <div
          className="testimonial-photo"
          tw="lg:flex-none p-10 rounded-t-lg md:rounded-t-none md:rounded-l-lg">
          <Img fluid={testimonial.image.fluid} />
        </div>
        <div
          className="testimonial-info"
          tw="col-span-2 xl:py-12 md:flex-grow bg-air justify-self-center text-navy pt-8 py-8 px-8 xl:px-16 rounded-b-lg md:rounded-b-none md:rounded-r-lg text-center md:text-left">
          <img src={Quote} tw="m-auto mb-6 md:ml-0" />
          <h3>{testimonial.text}</h3>
          <div tw="md:flex justify-between mt-8 text-center md:text-left">
            <div>
              <span tw="text-ocean text-lg">{testimonial.name}</span>
              <span tw="block text-ocean text-lg">{testimonial.role}</span>
            </div>
            <div tw="mt-4 md:mt-0">
            </div>
          </div>
        </div>
      </div>
    </Testimonial1Wrapper>
  )
}
export default Testimonial1
