import React, { useState, useEffect } from 'react'
import { graphql, Link, navigate } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import background from '../images/Hero_Background_Image_01.svg'
import IntroBackground from '../images/Software_image.svg'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'
import Benefits from '../components/home/benefits'
import Intro from '../components/home/intro'
import Testimonial from '../components/home/testimonial'
import Testimonial1 from '../components/home/testimonial-1'
import Features from '../components/home/features'
import Industries from '../components/home/industries'
import Partners from '../components/home/partners'
import Implementation from '../components/home/implementation'
import WhoWeAre from '../components/home/who-we-are'
import AIWork from '../components/services/ai-work'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-modal-video/css/modal-video.css'
import SEO from '../components/SEO/SEO'
import ModalVideo from 'react-modal-video'
import WatchElementAnimate from '../components/watch-element-animate'
import Play from '../images/Play-small.svg'
import LocalizedLink from '../components/localized-link'
import { withPreview } from 'gatsby-source-prismic'
import Slider from 'react-slick'
import smile from '../images/smile.png'
import chidog from '../images/chidog.png'
import fire from '../images/fire.png'
import peachdish from '../images/peachdish.png'

// styles
const HeroWrapper = styled.div`
  background:#030406;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 138px 0;
  .hero-left {
    @media (min-width: 1024px) {
      max-width: 450px;
      margin-right: 84px;
    }
  }
  @media (max-width: 640px) {
    padding-bottom: 50px;
  }
`
const HomeWrapper = styled.div`
  padding-bottom: 4rem;
  .testimonial-container {
    position: relative;
    z-index: 10;
  }
  @media (min-width: 769px) {
    .home-intro {
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  @media (min-width: 1441px) {
    .home-intro {
      margin-bottom: 20px;
      margin-top: 20px;
      background: #F5F4F2;
    }
  }
`
const SliderWrapper = styled.div`
  margin-top: -50px;
  img {
    padding: 15px;
  }
`
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'en'
  }
  const lang =
    navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return 'en'

  switch (lang) {
    case 'de':
      return 'de'
    default:
      return 'en'
  }
}
// markup
const IndexPage = ({ data: { prismicHome }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const [isOpen, setOpen] = useState(false)
  const settings = {
    dots: false,
    initialSlide: 0,
    arrows: false,
    speed: 500,
    centerMode: true,
    infinite: true,
    centerPadding: "10px",
    slidesToShow: 2.1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true
  }
  useEffect(() => {
    const urlLang = getRedirectLanguage()
    if (localStorage.getItem('locale') === null && urlLang === 'de') {
      navigate(`/${urlLang}`)
    }
  }, [])
  return (
    <main>
      <SEO
        title={prismicHome.data.meta_title}
        desc={prismicHome.data.meta_description}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div tw="lg:flex justify-between">
            <div tw="md:flex-none whitespace-normal">
              <h1 tw="text-white max-w-3xl">{prismicHome.data.hero_headline.text}</h1>
              <p tw="text-xl text-white py-8 max-w-3xl">
                {prismicHome.data.hero_subheadline.text}
              </p>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="EMu5jiHLsQM"
          youtube={{
            autoplay: 1,
            mute: 1,
          }}
          onClose={() => setOpen(false)}
        />
      </HeroWrapper>
      <SliderWrapper>
        <div tw="container">
          <Slider {...settings}>
            
                <img src={smile} />
            
                <img src={chidog} />
            
                <img src={fire} />
                <img src={peachdish} />
          </Slider>
        </div>
      </SliderWrapper>
      <HomeWrapper>
        {prismicHome.data.body.map((b, index) => {
          switch (b.slice_type) {
            case 'benefits':
              return <Benefits benefits={b} />
            case 'intro':
              return (
                <div className="home-intro">
                  <Intro intro={b} bg={IntroBackground} />
                </div>
              )
            case 'client_testimonial':
              return (
                <WatchElementAnimate
                  className={`.index__testimonial__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`index__testimonial__animated__${index}`}>
                    <Testimonial testimonial={b.primary} />
                  </div>
                </WatchElementAnimate>
              )
            case 'client_testimonial_1':
              return (
                <WatchElementAnimate
                  className={`.index__testimonial__1__animated__${index}`}
                  animateName={'animate__fadeInUp'}>
                  <div className={`index__testimonial__1__animated__${index} testimonial-container`}>
                      <Testimonial1 testimonial={b.primary} />
                  </div>
                </WatchElementAnimate>
              )  
            case 'features':
              return <Features features={b.primary} items={b.items} />
            case 'industries':
              return (
                <Industries
                  industries={b.primary}
                  items={b.items}
                  format={'select'}
                  i18n={i18n}
                />
              )
            case 'partners':
              return <Partners partner={b.primary} items={b.items} />
            case 'lets_make_ai_work':
              return (
                <AIWork
                  ai={b.primary}
                  items={b.items}
                  flow={'row'}
                  i18n={i18n}
                />
              )
            case 'who_we_are':
              return <WhoWeAre who={b.primary} i18n={i18n} />
            default:
              return null
          }
        })}
      </HomeWrapper>
    </main>
  )
}

export default withPreview(IndexPage)

export const pageQuery = graphql`
  query IndexQuery($locale: String!) {
    prismicHome(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        hero_button
        body {
          ... on PrismicHomeBodyIntro {
            slice_type
            primary {
              description {
                html
              }
              headline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyFeatures {
            slice_type
            primary {
              headline {
                html
              }
              feature_description
            }
            items {
              card_icon {
                fixed(width: 50) {
                  ...GatsbyPrismicImageFixed
                }
              }
              card_description
              card_headline
            }
          }
          ... on PrismicHomeBodyBenefits {
            items {
              text {
                html
              }
            }
            slice_type
          }
          ... on PrismicHomeBodyClientTestimonial {
            primary {
              name
              role
              text
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                link_type
                type
                lang
                uid
              }
              button
            }
            slice_type
          }
          ... on PrismicHomeBodyClientTestimonial1 {
            primary {
              name
              role
              text
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            slice_type
          }
          ... on PrismicHomeBodyIndustries {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              name {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
              benefit {
                html
              }
              link {
                url
              }
            }
          }
          ... on PrismicHomeBodyPartners {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              logo {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyImplementation {
            slice_type
            primary {
              headline {
                text
              }
            }
            items {
              list_headline
              list_description
            }
          }
          ... on PrismicHomeBodyLetsMakeAiWork {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              description {
                html
              }
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicHomeBodyWhoWeAre {
            slice_type
            primary {
              top_headline {
                text
              }
              link {
                url
                slug
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              headline {
                text
              }
              description {
                html
              }
            }
          }
        }
        hero_subheadline {
          text
        }
        hero_background {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        hero_headline {
          text
        }
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
