import React from 'react'
import tw, { styled } from 'twin.macro'
import { useForm } from 'react-hook-form'
import { QForm, SmallSolidTurquoiseInput } from '../../utils/styles'
const ImplementationWrapper = styled.div`
  .list-index {
    font-weight: 900;
    font-size: 100px;
    line-height: 120px;
    text-align: center;
    color: #edf4fb;
  }
  .list-title {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
`

const Implementation = ({ implementation, items }) => {
  const { register, handleSubmit } = useForm()
  const onSubmit = (data) => console.log(data)
  return (
    <ImplementationWrapper tw="container py-8 md:py-14">
      <div tw="grid gap-y-11 md:grid-cols-5 gap-x-12">
        <div tw="text-center md:text-left md:col-span-3">
          <h2 tw="text-navy">{implementation.headline.text}</h2>
          {items.map((item, key) => {
            return (
              <div
                key={key}
                tw="flex flex-col text-center md:flex-row md:text-left">
                <div tw="relative">
                  <div className="list-index">0{key + 1}</div>
                  <div
                    tw="text-teal text-sm uppercase tracking-widest font-bold absolute top-1/2 left-1/2"
                    className="list-title">
                    {item.list_headline}
                  </div>
                </div>

                <div tw="flex items-center md:ml-7">
                  <p tw="text-lg text-ocean">{item.list_description}</p>
                </div>
              </div>
            )
          })}
        </div>
        <QForm tw="md:col-span-2">
          <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col">
            <h3>Get in touch</h3>
            <input
              {...register('firstName', { required: true })}
              placeholder="First name"
              className="q-input"
            />
            <input
              {...register('lastName', { required: true })}
              placeholder="Last name"
              className="q-input"
            />
            <input
              {...register('workEmail', { required: true })}
              placeholder="Work email"
              className="q-input"
            />
            <input
              {...register('companyName', { required: true })}
              placeholder="Company name"
              className="q-input"
            />
            <input
              {...register('jobTitle', { required: true })}
              placeholder="Job title"
              className="q-input"
            />
            <SmallSolidTurquoiseInput type="submit">
              Request a meeting
            </SmallSolidTurquoiseInput>
          </form>
        </QForm>
      </div>
    </ImplementationWrapper>
  )
}
export default Implementation
